import { useEffect, useState } from "react";
import { fetchYoutubeGallery } from "../../../services/operations/youtubeAPI";
import { FaYoutube, FaEye, FaThumbsUp, FaCalendarAlt } from "react-icons/fa";

// Your YouTube channel ID
const YOUTUBE_CHANNEL_ID = "UCcaseLR5ph-XBMpHekcsHtQ";

// Fallback mock data in case the API fails
const MOCK_VIDEOS = [
  {
    youtubeId: "dQw4w9WgXcQ",
    title: "Sample YouTube Video 1",
    description: "This is a fallback video shown when the API is not working.",
    type: "video"
  },
  {
    youtubeId: "9bZkp7q19f0",
    title: "Sample YouTube Video 2",
    description: "This is another fallback video shown when the API is not working.",
    type: "video"
  },
  {
    youtubeId: "kJQP7kiw5Fk",
    title: "Sample YouTube Video 3",
    description: "This is a third fallback video shown when the API is not working.",
    type: "video"
  }
];

const YouTubeGallery = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [useFallback, setUseFallback] = useState(false);

  useEffect(() => {
    const getVideos = async () => {
      try {
        setLoading(true);
        setError(null);
        const result = await fetchYoutubeGallery();
        
        if (result && result.length > 0) {
          setVideos(result);
        } else {
          console.warn("No videos returned from API, using fallback data");
          setUseFallback(true);
          setVideos(MOCK_VIDEOS);
        }
      } catch (err) {
        console.error("Error in YouTubeGallery component:", err);
        setError(err.message || "Failed to load videos");
        setUseFallback(true);
        setVideos(MOCK_VIDEOS);
      } finally {
        setLoading(false);
      }
    };

    getVideos();
  }, []);

  // Format view count with commas (e.g., 1,234,567)
  const formatCount = (count) => {
    return count ? parseInt(count).toLocaleString() : "N/A";
  };

  // Format date to readable format
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  if (loading) {
    return (
      <div className="w-11/12 max-w-maxContent mx-auto py-10">
        <h2 className="text-3xl font-bold text-center mb-8 flex items-center justify-center gap-2">
          <FaYoutube className="text-red-600 text-4xl" />
          Our YouTube Videos
        </h2>
        <div className="flex justify-center items-center h-40">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-11/12 max-w-maxContent mx-auto py-10">
      <h2 className="text-3xl font-bold text-center mb-8 flex items-center justify-center gap-2">
        <FaYoutube className="text-red-600 text-4xl" />
        Our YouTube Videos
      </h2>
      
      {useFallback && (
        <div className="bg-richblack-700 border border-richblack-600 text-white p-4 mb-6 rounded-md text-center">
          <p className="text-lg">
            Showing sample videos for demonstration purposes.
            {process.env.NODE_ENV === 'development' && error && (
              <span className="block mt-2 text-sm text-yellow-300">
                Developer note: {error}
              </span>
            )}
          </p>
        </div>
      )}
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {videos.map((video) => (
          <div key={video.youtubeId} className="rounded-lg overflow-hidden shadow-lg bg-richblack-800 hover:transform hover:scale-105 transition-transform duration-300">
            <div className="aspect-video">
              <iframe
                className="w-full h-full"
                src={`https://www.youtube.com/embed/${video.youtubeId}`}
                title={video.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="p-4">
              <h3 className="text-lg font-semibold mb-2">{video.title}</h3>
              {/* {video.description && (
                <p className="text-sm text-gray-300 line-clamp-2 mb-2">{video.description}</p>
              )}
               */}
              <div className="flex flex-wrap gap-4 text-sm text-gray-400 mt-2">
                {video.publishedOnYouTube && (
                  <div className="flex items-center gap-1">
                    <FaCalendarAlt />
                    <span>{formatDate(video.publishedOnYouTube)}</span>
                  </div>
                )}
                {video.viewCount && (
                  <div className="flex items-center gap-1">
                    <FaEye />
                    <span>{formatCount(video.viewCount)}</span>
                  </div>
                )}
                {video.likeCount && (
                  <div className="flex items-center gap-1">
                    <FaThumbsUp />
                    <span>{formatCount(video.likeCount)}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      
      {/* View More Button */}
      <div className="text-center mt-8">
        <a
          href={`https://www.youtube.com/channel/${YOUTUBE_CHANNEL_ID}`}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center gap-2 bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-6 rounded-full transition-colors"
        >
          View More on YouTube <FaYoutube className="text-xl" />
        </a>
      </div>
    </div>
  );
};

export default YouTubeGallery;
