import { toast } from "react-hot-toast";
import { apiConnector } from "../apiconnector";
import { youtubeEndpoints } from "../apis";

const { GET_YOUTUBE_GALLERY_API } = youtubeEndpoints;

export const fetchYoutubeGallery = async () => {
  let result = [];
  const toastId = toast.loading("Loading YouTube videos...");
  try {
    const response = await apiConnector("GET", GET_YOUTUBE_GALLERY_API);
    
    if (!response) {
      throw new Error("No response from YouTube API");
    }
    
    if (!response.data) {
      throw new Error("No data in YouTube API response");
    }
    
    // The backend returns an array of videos directly
    if (Array.isArray(response.data)) {
      result = response.data;
    } 
    // If for some reason it's wrapped in another object
    else if (response.data.data && Array.isArray(response.data.data)) {
      result = response.data.data;
    }
    // If we got here but still don't have an array
    else {
      throw new Error("Unexpected response format from YouTube API");
    }
  } catch (error) {
    console.error("YOUTUBE_GALLERY_API ERROR:", error);
    toast.error(error.message || "Failed to load YouTube videos");
  }
  toast.dismiss(toastId);
  return result;
};
